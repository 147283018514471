import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import React from 'react'
import styled from 'styled-components'

const TagGroupLi = styled.li`
    font-family: Montserrat;
    color: #48c9d1;
    list-style-type: none;
`
export const TagGroup = tag => (
    <TagGroupLi key={tag.fieldValue}>
        #{' '}
        <Link
            to={`/tags/${kebabCase(tag.fieldValue)}/`}
            style={{ textDecoration: 'none' }}
        >
            {tag.fieldValue} ({tag.totalCount})
        </Link>
    </TagGroupLi>
)

export const Tag = tag => (
    <TagGroupLi key={tag}>
        #{' '}
        <Link
            to={`/tags/${kebabCase(tag)}/`}
            style={{ textDecoration: 'none' }}
        >
            {tag}
        </Link>
    </TagGroupLi>
)
