import { graphql } from 'gatsby';
// Utilities
import PropTypes from 'prop-types';
import React from 'react';
// Components
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { TagGroup } from '../components/TagGroup';

const Title = styled.h1`
    font-family: Montserrat;
    font-size: 70px;
    color: #48c9d1;
    margin-bottom: 14px;
`

const TagsPage = ({
    data: {
        allMarkdownRemark: { group },
        site: {
            siteMetadata: { title },
        },
    },
}) => (
    <Layout>
        <div>
            <Helmet title={title} />
            <div>
                <Title>Tags</Title>
                <ul>{group.map(TagGroup)}</ul>
            </div>
        </div>
    </Layout>
)

TagsPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldValue: PropTypes.string.isRequired,
                    totalCount: PropTypes.number.isRequired,
                }).isRequired,
            ),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
}

export default TagsPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`
